export const get_open_issues = {
    getAll: {
        url: 'admin/getOpenIssues',
        method: 'GET',
        id: null,
        params: null
    },
    imageReportById: {
        url: 'admin/imageReportById/',
        method: 'GET',
        id: null,
        params: null
    },
    accept: {
        url: 'admin/report_image/accept/',
        method: 'GET',
        id: null,
        params: null
    },
    reject: {
        url: 'admin/report_image/reject/',
        method: 'GET',
        id: null,
        params: null
    }
}