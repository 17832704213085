<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import router from "@/router";
import { get_open_issues } from "@/config/api/report_image";

// $('a[data-toggle="dropdown"]').click(function() {
//   dropDownFixPosition($(this), $('.dropdown-menu'));
// });

export default {
  page: {
    title: "Reported Issues",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  name: "app",
  data() {
    return {
      title: "Reported Issues",
      issues: [],
      selectedIssue: null,
      fields: [
        { key: "projectId.project_id", sortable: true, label: "Project ID" },
        { key: "description", sortable: true, label: "Description" },
        { key: "status", sortable: true, label: "Status" },
        { key: "reported_by.name", sortable: true, label: "Reported By" },
        { key: "createdAt", sortable: true },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      activeTab: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.issues.length;
  },
  methods: {
    openIssue(row) {
      this.selectedIssue = row;
      router.push({
        name: "ReportedImage",
        params: { issue_id: this.selectedIssue._id },
      });
      
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    updatePage(page) {
      this.loadData(page);
    },
    async loadData(page = 1) {
      this.issues = [];
      this.loading = true;
      try {
        const api = get_open_issues.getAll;
        var filter = { page: page };
        //TODO: BETTER GET FROM STATE
        let loggedInUser = JSON.parse(localStorage.getItem("user"));
        // We need to get logged in user details
        if (loggedInUser.user.companies_owned != null) {
          filter["user"] = loggedInUser.user._id;
        }
        // console.log(filter);
        // api.params = filter;

        let res = await this.generateAPI(api);
        console.log(res);
        this.issues = res.data.issues;
        // this.supportData = res.data.support_requests.docs;
        // this.totalRows = res.data.support_requests.totalDocs;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    navigate(path, id) {
      this.$router.push({
        path: path,
        query: { id: id },
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom" v-model="activeTab">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Reported Issues</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="issues"
                    :fields="fields"
                    responsive="sm"
                    :busy="$store.state.api.loading"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    @row-clicked="openIssue"
                    tbody-tr-class="rowClass"
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(id)="row">
                      {{ row.item.projectId.project_id }}
                    </template>
                    <template v-slot:cell(description)="row">
                      {{ row.item.description }}
                    </template>
                    <template v-slot:cell(status)="row">
                      {{ row.item.status == 0 ? "Open" : "Closed" }}
                    </template>
                    <template v-slot:cell(reported_by)="row">
                      {{ row.item.reported_by.name }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("lll") }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          @change="updatePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
